import React from "react";
import { initializeApp } from 'firebase/app';

// TODO: Replace the following with your app's Firebase project configuration


import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from 'history';

const firebaseConfig = {
  apiKey: "AIzaSyDyz-ZYp9a01GEQpPihy67IPgkNv5rFhxU",
  authDomain: "sally-painting-gallery-website.firebaseapp.com",
  databaseURL: "https://sally-painting-gallery-website.firebaseio.com",
  projectId: "sally-painting-gallery-website",
  storageBucket: "sally-painting-gallery-website.appspot.com",
  messagingSenderId: "266103258927",
  appId: "1:266103258927:web:61cff221f42505a3bb6bda",
  measurementId: "G-3E32589BT4",
};
const app = initializeApp(firebaseConfig);

// for (let index = 1; index < 47; index++) {
//   const newPostKey = firebase
//   .database()
//   .ref("student")
//   .push().key;
//   firebase
//   .database()
//   .ref("student/" + newPostKey)
//   .set({ url: `student_${("00" + index).slice(-2)}.jpg`});
// }

// const newPostKey = firebase
//   .database()
//   .ref("announcement")
//   .push().key;
// firebase
//   .database()
//   .ref("announcement/" + newPostKey)
//   .set({ body: "From 9:00 AM to 5:30 PM Sally has 2 slots of 3:30 hours", title: "Friday classes are now available." });




ReactDOM.render(<App/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
