import * as firebase from 'firebase/app';
import { getDatabase,  ref, onValue } from "firebase/database";


import React, { useEffect, useState } from "react";
import "./gallery.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GalleryProps { }

export const Gallery = ({}: GalleryProps) => {

    const [data, setData] = useState([] as any[]);
    const database =  getDatabase();
    const rf = ref(database, "painting");

    const getPaintingList = () => {
          onValue(rf , (value: any) => {
            setData(
              Object.entries(value.val()).map((x: any) => ({
                id: x[0],
                price: x[1].price,
                title: x[1]?.title,
                url: x[1]?.url,
              })),
            );
          });
      };

    useEffect(() => {getPaintingList(); }, []);
    // tslint:disable-next-line: max-line-length
    return <> <h2> A small sample of Sally's artkworks.</h2>
      <div className="scroller">
    {data.map( (info: any) => (<div  key={info.id} className="images"> <img  src={`${process.env.PUBLIC_URL}/paintings/${info.url}`}/></div> ) )} </div> </>;
};
