import React, { ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";
import "./header-component.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HeaderComponentProps { }

export const HeaderComponent = ():  ReactElement => {
    const path = useLocation().pathname;

    const pathMatch = (p: string): string  => {
      return p === path ? "selected" : "";
    };

    return (
      <>
        <div className="header-menu">
          <ul>
            <li className={pathMatch("/") }>
              <Link to="/">Home</Link>
            </li>
            <li className={pathMatch("/Gallery")}>
              <Link to="/Gallery">Gallery</Link>
            </li>
            <li className={pathMatch("/Students")}>
              <Link to="/Students">
                Students
              </Link>
            </li>
            <li className={pathMatch("/ArtClasses")}>
              <Link to="/ArtClasses">Classes</Link>
            </li>
            <li className={pathMatch("/AboutMe")}>
              <Link to="/AboutMe">About Me</Link>
            </li>
            <li className={pathMatch("/ContactMe")}>
              <Link to="/ContactMe">Contact Me</Link>
            </li>
          </ul>
        </div>
      </>
    );
};
