import React from 'react'
import './about-me.scss'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AboutMeProps {
}

export const  AboutMe = ({}: AboutMeProps) => {
    return <>
    <h2>About me</h2>
    <img className="img-about" src="/sally-photo.jpg"/>
    Sally was born in 1979. Her love of painting started from early childhood. She attended many art schools and had the opportunity to be trained by some of the greatest realist and impressionist painters of Iran. Her painting talent helped her to quickly become one of the most distinguished art students during her teenage years. Sally has now been teaching painting in different media for nearly ten years. Her paintings are mostly inspired by nature, cultural heritage, people’s living environment and sentimental selections. Sally expresses her feelings through expressive brush strokes and vibrant colours. Her paintings are influenced by Impressionism, Expressionism and abstract painting styles.
    
    <img className="img-about" src="/sally.jpg"/>
    <iframe className="vid" width="560" height="315" src="http://www.youtube.com/embed/koWkb9PLaIs" allowFullScreen ></iframe>
    <iframe  className="vid" width="560" height="315" src="http://www.youtube.com/embed/6FZK4ljLAXY?list=UUgli72k4NpN7UVA4vzSLpkA" allowFullScreen ></iframe>
    <img className="img-about" src="/demo1.jpg"/>
    <img className="img-about" src="/demo2.jpg"/>
    <img className="img-about" src="/demo3.jpg"/>
    <img className="img-about" src="/demo4.jpg"/>
     </>
}