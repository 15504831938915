import React, { useState, useEffect } from 'react'
import './students.scss'
import * as firebase from 'firebase/app';
import { getDatabase, onValue, ref } from 'firebase/database';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StudentsProps {
}

export const Students = ({}: StudentsProps) =>{
    const [data, setData] = useState([] as any[]);
    const database =  getDatabase();
    const rf = ref(database, "student");

    const getPaintingList = () => {
          onValue(rf , (value: any) => {
            setData(
              Object.entries(value.val()).map((x: any) => ({
                id: x[0],
                price: x[1].price,
                title: x[1]?.title,
                url: x[1]?.url,
              })),
            );
          });



      };

    useEffect(() => {getPaintingList(); }, []);
    // tslint:disable-next-line: max-line-length
    return <> <h2>    Following paintings are the artworks of Sally's students.</h2>
         <div className="scroller">
    {data.map( (info: any) => (<div  key={info.id} className="images"> <img  src={`${process.env.PUBLIC_URL}/students/${info.url}`}/></div> ) )}</div> </>;
}