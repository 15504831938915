import React, { useState, useEffect } from "react";
import "./main-page-content.scss";
import * as firebase from 'firebase/app';
import { Announcement } from "./Announcement";
import { getDatabase, onValue, ref } from "firebase/database";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MainPageContentProps { }
export const MainPageContent = ({ }: MainPageContentProps) => {
  const [data, setData] = useState([] as Announcement[]);
  const getAnnouncementData = () => {
    const database =  getDatabase();
    const rf = ref(database, "announcement");
    onValue(rf , (value: any) => {
      setData(
        Object.entries(value.val()).map((x: any) => ({
          id: x[0],
          title: x[1]?.title,
          body: x[1]?.body
        }))
    );
    });
  };

  useEffect(() => {
    getAnnouncementData();
  }, []);

  return (
    <div className="body">
      <img className="header" />

      <h1>Brisbane's best painting workshop/classes</h1>

      <fieldset>
        <legend>Updates </legend>
        {data.map((x: Announcement) => (
          <div className="wrapper" key={x.id}>
            <strong>
              <span> {x?.title}: </span>
            </strong>
            {x?.body}
          </div>
        ))}
      </fieldset>
      Classes Time Table:
      <div style={{ justifyContent: "center", display: "flex" }}>
        <img className="img-about" src="/timetable.png" />
        </div>

      <strong style={{ marginTop: "30px", display: "block" }}>
        <span>Who can benefit from Sally&#39;s Painting Classes ?</span>
      </strong>

      <div className="p">
        Everyone from beginners to serious artists. Sally can help you achieve
        your goals weather working towards a show, launching a professional
        career or just enjoying being creative.
      </div>

      <strong>Classes in the following Media:</strong>

      <ul>
        <li>Oil color on canvas</li>
        <li>Colored pencil</li>
        <li>Conte</li>
        <li>Graphite pencil</li>
      </ul>

      <strong>
        <div>Painting subjects: </div>
      </strong>

      <div className="p">
        As varied as your imagination. Sally can help take you from your first
        steps through to the most advanced painting techniques. Learn to paint
        like a master in most styles [impressionistic, realistic , abstract
        etc...].
      </div>

      <ul>
        <li>Portrait painting</li>
        <li>Landscape painting</li>
        <li>Floral &amp; still life painting</li>
        <li>Drawing - Including anatomical study</li>
      </ul>

      <iframe className="vid" width="560" height="315" src="https://www.youtube.com/embed/nXGcmUsprLo" allowFullScreen></iframe>
      <iframe className="vid" width="560" height="315" src="https://www.youtube.com/embed/gWDiZKYsShM" allowFullScreen></iframe>

    </div>
  );
};
