import React from "react";
import "./art-classes.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ArtClassesProps {
}

export const ArtClasses = ({ }: ArtClassesProps) => {
    return (<>

        <h4>All you need to know...</h4>
        <ul>
            <li><h3>Location </h3>
                <ul>
                    <li><a target="_blank" href="https://www.google.com.au/maps/place/1053+Logan+Rd,+Holland+Park+West+QLD+4121/@-27.5227738,153.0623944,1357m/data=!3m2!1e3!4b1!4m6!3m5!1s0x6b915b03b28c042f:0x1346c060f1d376c6!8m2!3d-27.5227786!4d153.0649693!16s%2Fg%2F11bw41clcn?entry=ttu">
          <strong>1053 Logan Rd, Holland Park West QLD 4121</strong>
        </a>.  </li>
                    <li>On site parking available.</li>
                </ul>
            </li>

        </ul>
        <ul>
            <li><h3>Available time slots</h3>

                <ul>
                <img className="img-about" src="/timetable.png"/>
                </ul>
            </li>

            <li><h3>
                How to enrol ?
            </h3>
                <ul>
                    <li> <strong>$42</strong> per session. You can pay fortnightly <strong>($84)</strong> or monthly <strong>($168)</strong> prior to the block of sessions.</li>
                    <li>You are welcome to start at <strong>any time</strong> and you will pay as long as you wish to attend the classes.</li>
                    <li>You need to read and accept Sally Painting Gallery <strong> <a className="link" href="./TC.pdf" target="_blank">terms and conditions</a></strong>.</li>
                    <li>Students are required to give at least one week notice if you are going to be <strong>absent</strong> for the
                    following week. Otherwise you will need to pay for your class as normal.</li>
                    <li>Contact <strong><a href="mailto:info@sallypaintinggallery.com">Sally</a> to complete your enrollment</strong>.</li>
                    <li>Provide materials. See the <strong> <a className="link" target="_blank" href="Materiallist.pdf">material list</a></strong>.</li>
                </ul>
            </li>
        </ul>
        <ul>
            <li><h3>Class types</h3>
                <ul>
                    <li>Painting classes in oil colour (mentoring and step-by-step).</li>
                    <li>Drawing classes.</li>
                    <li>
                        Class fees:  Step-by-step and Mentoring sessions : $42.00 for 3:30 hours.</li>
                    <li>Weekend classes : $42.00</li>
                    <li>This fee covers Sally's teaching and provides you with a table and an easel. Materials and other costs are not included.
                    Contact Sally for some guidance before investing in art materials.
                </li>
                </ul>
            </li>

            <li> <h3>Skill levels</h3>
                <ul>
                    <li>
                        People of <strong>all skill levels</strong> are welcome. Sally loves inspiring beginners. Teaching is tailored to each student's level.
                </li>
                </ul>

            </li>
        </ul>

        <ul>
            <li>
                <h3>Mentoring classes</h3>
                <ul>
                    <li>YOU choose the subject.
                    YOU choose the medium.
                    YOU choose the style.
                    Sally helps you to make it a reality.
                        Then you hang it up at home!</li>
                </ul>
            </li>
        </ul>

        <ul>
            <li>
                <h3>Step-by-step classes</h3>
                There will be a demonstration about one hour every session and then students will have the rest of the class time to work on their paintings.
             Every term Sally introduces a new technique with a new subject. Hence, everybody is working on the same reference photo between 6 to 8 sessions (depending on complexity of the subject).
             Most students have found they benefit most by doing 2 or 3 courses in a row.


            </li>
            <li>
                <h3>Drawing classes</h3>
                You can expect to be immersed in...
            <ul>
                    <li></li>
                    <li>Line drawing</li>
                    <li>Shading</li>
                    <li>Tonal work</li>
                    <li>In monochrome or color for...
                <ul>
                            <li>Portraits</li>
                            <li>Anatomical studies</li>
                            <li>Still life</li>
                        </ul>
                    </li>


                </ul>
            </li>
        </ul>
        Sally is adept with all drawing media and especially Graphite pencil, Conte with paper stump &amp; coloured pencils.


    </>);
};
