import React from "react";
import "./App.scss";

import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import { AboutMe } from "./AboutMe/AboutMe";
import { ArtClasses } from "./ArtClasses/ArtClasses";
import { ContactMe } from "./ContactMe/ContactMe";
import { Gallery } from "./Gallery/Gallery";
import { HeaderComponent } from "./HeaderComponent/HeaderComponent";
import { MainPageContent } from "./MainPageContent/MainPageContent";
import { Students } from "./Students/Students";

const App: React.FC = () => {
  return (
    <HashRouter hashType={"slash"}>
        <HeaderComponent />
        <Switch>
          <>
            <div className="content">
              <Route exact path="/Gallery" component={Gallery}></Route>
              <Route exact path="/" component={MainPageContent}></Route>
              <Route exact path="/ArtClasses" component={ArtClasses}></Route>
              <Route exact path="/Students" component={Students}></Route>
              <Route exact path="/AboutMe" component={AboutMe}></Route>
              <Route exact path="/ContactMe" component={ContactMe}></Route>
            </div>
            <div className="footer"></div>
          </>
        </Switch>
    </HashRouter>
  );
};

export default App;
