import React, { useState } from "react";
import "./contact-me.scss";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContactMeProps { }

export const ContactMe = ({ }: ContactMeProps) => {
  const [mapDefaults, setMapDefaults] = useState({
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  });

  return <>
    <h2>Contact me</h2>
        <div className="p"> E-mail: <a href="mailto:info@sallypaintinggallery.com"><strong>info@sallypaintinggallery.com</strong></a>
        </div>
        <div className="p">
          <strong >Mobile:  <a href="tel:0451977418">0451 977 418</a> ( Sally ).</strong>
        </div>
      <div className="p" style={{ display: "flex", alignItems : "center" }}>
        Address: <a target="_blank" href="https://www.google.com.au/maps/place/1053+Logan+Rd,+Holland+Park+West+QLD+4121/@-27.5227738,153.0623944,1357m/data=!3m2!1e3!4b1!4m6!3m5!1s0x6b915b03b28c042f:0x1346c060f1d376c6!8m2!3d-27.5227786!4d153.0649693!16s%2Fg%2F11bw41clcn?entry=ttu">
          <strong>1053 Logan Rd, Holland Park West QLD 4121</strong>
        </a>
      </div>
      <iframe width="300" height="250" id="gmap_canvas"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3538.279521817848!2d153.06239437718168!3d-27.52277381897295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b915a74baf0a8cb%3A0xfe449c09f44013bf!2sSally%20Painting%20Gallery!5e0!3m2!1sen!2sau!4v1705972153308!5m2!1sen!2sau"></iframe>

    <div className="p">
      Facebook:
      <a target="_blank" href="https://www.facebook.com/SallyPaintingGallery">
        <strong>Sally Painting Gallery</strong>
        </a>
    </div>

    <div className="p">
      Instagram:
      <a target="_blank" href="https://www.instagram.com/sallypaintinggallery/">
        <strong>@SallyPaintingGallery</strong>
        </a>
    </div>
      <div className="p">
        To see Sally painting gallery students reviews please click <a target="_blank" href="https://www.google.com/search?q=sally+painting+gallery&rlz=1C1CHBF_enAU876AU876&oq=sally+painting+gallery&aqs=chrome..69i57j69i60l3.152j0j1&sourceid=chrome&ie=UTF-8#lrd=0x6b915a74baf0a8cb:0xfe449c09f44013bf,1,,,">
          <strong>here</strong>
          </a>
      </div>
      <div className="p">
        Sally strongly recommends <a target="_blank" href="http://www.artshedbrisbane.com.au/">Art shed Brisbane</a>,
        as much for their friendly and helpful service as for the unbeatable prices.
       <a target="_blank" href="http://www.artshedbrisbane.com.au/">
       </a>
      </div>

  </>;
};
